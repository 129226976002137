import React from 'react'
import { Link, graphql } from 'gatsby'
import get from 'lodash/get'
// import { Helmet } from 'react-helmet'
import SEO from '../components/seo'
import styles from './blog.module.scss'
import Layout from '../components/layout'
import Hero from '../components/hero-post'
import ArticlePreview from '../components/article-preview'

class BlogIndex extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const posts = get(this, 'props.data.allContentfulBlogPost.edges')
    const section = { title: "blog", description: "idk what I'm talking about..." };

    return (
      <Layout location={this.props.location}>
          {/* <Helmet title={siteTitle} /> */}
          <SEO 
            title={section.title}
            description={section.description} 
          />
          <Hero data={section} />
          <section className="page container">
            <div className={styles.postDetails}>
              <h2 className="section-headline">All Posts</h2>
            </div>
            <div className={styles.postList}>
              {posts.map(({ node }) => {
                return (
                  <ArticlePreview article={node} key={node.slug} />
                )
              })}
            </div>
          </section>
      </Layout>
    )
  }
}

export default BlogIndex

export const pageQuery = graphql`
  query BlogIndexQuery {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulBlogPost(sort: { fields: [publishDate], order: DESC }) {
      edges {
        node {
          title
          slug
          publishDate(formatString: "MMMM Do, YYYY")
          tags
          heroImage {
            fluid(maxWidth: 350, maxHeight: 196, resizingBehavior: SCALE) {
              ...GatsbyContentfulFluid
            }
          }
          description {
            childMarkdownRemark {
              html
            }
          }
          body {
            childMarkdownRemark {
              fields {
                readingTime {
                  text
                }
              }
            }
          }
        }
      }
    }
  }
`
